import applicationsApiService from "@/services/modules/applications";
import clientsApiService from "@/services/modules/clients";
import usersApiService from "@/services/modules/users";
import workflowApiService from "@/services/modules/workflows";
import useNotesStore from "@/stores/notes";
import { getEnumKeyFromValue } from "@/helpers/common";
import {
  formatDate,
  formatDateCustom,
  formatMoney,
  formatPayloadWithCurrency,
  formatUserFullName
} from "@/helpers/formatting";
import {
  CLIENT_FUNDING_ADVISOR,
  FUNDING_ADVISOR,
  CLIENT_ANALYST,
  ANALYST,
  CLIENT_UNDERWRITER,
  UNDERWRITER,
  PHASES_FOR_LENDFLOW_FOR_FUNDING_APPS,
  SIGNED_DOC_FILE_TYPE,
  STATUS_DESCRIPTIONS,
  DEALS_CLIENT_OPTIONS_UNCATEGORIZED,
  PRODUCT_TYPE_ERC
} from "@/helpers/constants";
import { isGivenCountryCanada } from "@/helpers/deals";
import groupBy from "lodash/groupBy";
import { produce, setAutoFreeze } from "immer";
import type {
  ApplicationStatus,
  DealMode,
  DealVisibility,
  IAdvancedCfaPayload,
  IApplication,
  IApplicationAttribute,
  IApplicationCount,
  IApplicationMetrics,
  IApplicationsState,
  IApplicationsStateCommercialData,
  IApplicationTemporaryLinks,
  IBankAccount,
  IBankData,
  IBillingInformation,
  IBusiness,
  IBusinessLeadProfile,
  ICommercialData,
  IDealTimeline,
  IDealVisibilityUpdate,
  IDiscoveryCallAnswer,
  IDiscoveryCallQuestion,
  IEquipmentRentalApproval,
  IFilterSort,
  IFilterVisibility,
  IInformationProviderSummary,
  IOfferDeclinedInfo,
  ISendStipsToFunderPayload,
  ISmartFilter,
  ISystemPreferences,
  ITransaction,
  ITransactionPayload,
  StipPayload,
  WidgetInfo,
  ApplicationStatusPayload,
  WorkflowSnapshotStatus
} from "@/models/applications";
import type { IWorkflowStatus } from "@/models/workflows";
import type { PlaidData } from "@/models/applicationData/plaid";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type { IFile, IFilePayload, IPii, IResponseMeta } from "@/models/common";
import type { DealTelephone, IOwner, IUser } from "@/models/users";
import type { ILenderPlacement, IOffer } from "@/models/funders";
import type { IRootState } from "@/models/state";
import { NotableType, NoteType } from "@/enums/notes";
import { Delta } from "@vueup/vue-quill";
import type { IIndustry } from "@/models/options";
import queryString from "query-string";
import { SmartviewCategory } from "@/models/SmartviewCategory";
import { ScorecardType } from "@/enums/scorecards";
import i18n from "@/i18n";
import type { IClient } from "@/models/clients";
import { booleanToBinary } from "@/helpers/common";
import type { AxiosError } from "axios";
import type { OfferNote } from "@/models/offers";
import omit from "lodash/omit";
import set from "lodash/set";
import communicationService from "@/services/modules/communication";
import { AmplitudeEvent } from "@/enums/events";
import * as amplitude from "@amplitude/analytics-browser";

type editOfferMode = "save" | "update";

type OptionCallback = (id: number | null) => string | Record<string, string>;
type GetUsersByRoleParams = Parameters<
  typeof usersApiService.getUsersByRole
>[0];

function withFullName(users: Array<IUser>) {
  return users.map((user) => ({
    ...user,
    full_name: [user.first_name, user.last_name].filter(Boolean).join(" ") || ""
  }));
}

setAutoFreeze(false);

const getDefaultState = (): IApplicationsState => {
  return {
    meta: {} as IResponseMeta,
    all: [],
    fundingAdvisors: [],
    clientFundingAdvisors: {},
    underwriters: [],
    clientUnderwriters: {},
    analysts: [],
    clientAnalysts: {},
    active: {} as IApplication,
    previousId: "",
    commercialData: {} as IApplicationsStateCommercialData,
    nextId: "",
    currentPhase: 0,
    placements: [],
    singlePlacement: {} as ILenderPlacement,
    offers: [],
    count: {
      all: 0,
      active: 0,
      funded: 0,
      incomplete: 0,
      declined: 0
    },
    placementEmailPreview: "",
    offersLoading: false,
    loading: false,
    clientPreferences: { list_order: [], daily_monthly_stat_order: [] },
    filters: [],
    activeFilterId: -1,
    activeSmartviewCategory: SmartviewCategory.ALL,
    metrics: {
      business_lead_profile: {} as IBusinessLeadProfile,
      deal_timeline: {} as IDealTimeline,
      information_provider_summary: {} as IInformationProviderSummary
    },
    fundingTransactions: {},
    smartviewBuilderOpen: false,
    smartviewBuilderModel: null,
    systemPreferences: {
      middesk_irs_availability: null,
      deal_visibility: {} as DealVisibility
    },
    bankData: { bank_accounts: [] },
    moneythumbAnalytics: null,
    clientTrackingTokens: [],
    selectedAffiliatedBusinessId: null,
    selectedAffiliatedIndividualId: null,
    additionalTrackingTokens: [],
    currentAppAttributes: [],
    widgetInfo: null,
    plaidData: null,
    plaidDataLoading: false,
    moneythumbCfaAccountNumber: i18n.global.t(
      "DEALS.DEAL_PROGRESS.BANK_CONNECTION.SUMMARY"
    ),
    files: [],
    workflow_statuses: {}
  };
};

const state = getDefaultState();

const mutations: MutationTree<IApplicationsState> = {
  addOwner(state, payload: IOwner) {
    if (payload.is_primary) {
      if (state.active.personal_information) {
        state.active.personal_information.is_primary = false;
        state.active.business.other_owners = [
          ...state.active.business.other_owners,
          state.active.personal_information
        ];
        state.active.personal_information = payload;
      }
    } else {
      state.active.business.other_owners = [
        ...state.active.business.other_owners,
        payload
      ];
    }
  },
  unsetOwner(state, ownerId: number) {
    const indexInOtherOwners = state.active.business.other_owners.findIndex(
      ({ id }) => id === ownerId
    );

    if (indexInOtherOwners !== -1) {
      state.active.business.other_owners.splice(indexInOtherOwners, 1);
    } else if (state.active.personal_information?.id === ownerId) {
      state.active.personal_information = null;
    }
    state.files = state.files.filter(
      ({ personal_information }) =>
        !personal_information || personal_information?.id !== ownerId
    );
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAll(state, applications: IApplication[]) {
    state.all = applications.map((app) => ({
      ...app,
      business_name: app?.business?.business_legal_name ?? "",
      app_started: app.created_at ? formatDate(new Date(app.created_at)) : null,
      underwriter_full_name: app.underwriter
        ? formatUserFullName(app.underwriter)
        : "",
      client_underwriter_full_name: app.client_underwriter
        ? formatUserFullName(app.client_underwriter)
        : "",
      advisor_full_name: app.funding_advisor
        ? formatUserFullName(app.funding_advisor)
        : "",
      client_advisor_full_name: app.client_funding_advisor
        ? formatUserFullName(app.client_funding_advisor)
        : "",
      analyst_full_name: app.analyst ? formatUserFullName(app.analyst) : "",
      client_analyst_full_name: app.client_analyst
        ? formatUserFullName(app.client_analyst)
        : ""
    }));
  },
  setMetadata(state, meta: IResponseMeta) {
    state.meta = meta;
  },
  setActive(state, application: IApplication) {
    state.active = {
      ...state.active,
      ...application
    };

    if (state.active?.business?.address) {
      state.active.business.address.is_primary = true;
    }
    if (state.active?.personal_information?.address) {
      state.active.personal_information.address.is_primary = true;
    }
    state.active.business?.other_owners?.forEach((owner) => {
      if (owner.address) {
        owner.address.is_primary = true;
      }
    });
  },
  unsetActive(state) {
    state.active = {} as IApplication;
  },
  setPrevious(state, applicationId: string) {
    state.previousId = applicationId;
  },
  setNext(state, applicationId: string) {
    state.nextId = applicationId;
  },
  setFundingAdvisors(state, fundingAdvisors: IUser[]) {
    state.fundingAdvisors = withFullName(fundingAdvisors);
  },
  setClientFundingAdvisors(
    state,
    { clientId, advisors }: { clientId?: IClient["id"]; advisors: IUser[] }
  ) {
    state.clientFundingAdvisors[
      clientId || DEALS_CLIENT_OPTIONS_UNCATEGORIZED
    ] = withFullName(advisors);
  },
  setUnderwriters(state, underwriters: IUser[]) {
    state.underwriters = withFullName(underwriters);
  },
  setClientUnderwriters(
    state,
    {
      clientId,
      underwriters
    }: { clientId?: IClient["id"]; underwriters: IUser[] }
  ) {
    state.clientUnderwriters[clientId || DEALS_CLIENT_OPTIONS_UNCATEGORIZED] =
      withFullName(underwriters);
  },
  setAnalysts(state, analysts: IUser[]) {
    state.analysts = withFullName(analysts);
  },
  setClientAnalysts(
    state,
    { clientId, analysts }: { clientId?: IClient["id"]; analysts: IUser[] }
  ) {
    state.clientAnalysts[clientId || DEALS_CLIENT_OPTIONS_UNCATEGORIZED] =
      withFullName(analysts);
  },
  setActiveStatus(state, newStatus: ApplicationStatus) {
    state.active = {
      ...state.active,
      status: newStatus,
      status_description:
        state.active.workflow === 5
          ? PHASES_FOR_LENDFLOW_FOR_FUNDING_APPS[newStatus]
          : STATUS_DESCRIPTIONS[newStatus]
    };
  },
  setActiveBusinessInfo(state, businessInfo: IBusiness) {
    state.active.business = {
      ...state.active.business,
      ...businessInfo
    };
    if (state.active.business.address) {
      state.active.business.address.is_primary = true;
    }
  },
  setCurrentPhase(state, phase: number) {
    phase > -1 ? (state.currentPhase = phase) : (state.currentPhase = 0);
  },
  unsetCurrentPhase(state) {
    state.currentPhase = 0;
  },
  setPlacements(state, placements: ILenderPlacement[]) {
    state.placements = placements;
  },
  setSinglePlacement(state, data: ILenderPlacement) {
    state.singlePlacement = data;
  },
  unsetSinglePlacement(state) {
    state.singlePlacement = {} as ILenderPlacement;
  },
  setUpdatedPlacement(state, updatedPlacement: ILenderPlacement) {
    const storePlacement = state.placements.find(
      (placement) => placement.placement_id === updatedPlacement.placement_id
    );
    if (!storePlacement) {
      return;
    }
    Object.assign(storePlacement, updatedPlacement);
  },
  setClosingStatus(state, statusId: number) {
    state.active.closing_status_id = statusId;
  },
  setOfferStatus(state, { id, status }: IOffer) {
    state.offers = produce(state.offers, (draftOffers) => {
      const offerId = draftOffers.findIndex((offer) => offer.id === id);
      if (offerId !== -1) {
        draftOffers[offerId].status = status;
      }
    });
  },
  setOffers(state, offers: IOffer[]) {
    state.offers = offers.map((offer) => ({
      ...offer,
      funder_full_name: offer?.funder?.full_name || "-",
      product_type: offer?.placement?.product.name,
      offer_product_type: offer?.product?.name,
      risk_tiers: offer?.funder?.risk_tiers ?? [],
      tracking: offer?.placement?.tracking,
      status: offer.status,
      placement_id: offer?.placement?.placement_id,
      start_date: offer?.start_date
        ? formatDateCustom(offer?.start_date, "yyyy-MM-dd HH:mm")
        : null,
      end_date: offer?.end_date
        ? formatDateCustom(offer?.end_date, "yyyy-MM-dd HH:mm")
        : null
    }));
  },
  handleDeletedDocument(state, deletedId: number) {
    state.files = state.files.filter((file) => file.id !== deletedId);

    if (state.active.personal_information) {
      state.active.personal_information.signed_docs =
        state.active.personal_information.signed_docs.filter(
          (file) => file.file_id !== deletedId
        );
    }

    if (!state.active.business?.other_owners?.length) {
      return;
    }

    state.active.business.other_owners = state.active.business.other_owners.map(
      (owner) => ({
        ...owner,
        signed_docs: owner.signed_docs.filter(
          (file) => file.file_id !== deletedId
        )
      })
    );
  },
  setUpdatedFile(state, updatedFile: IFile) {
    state.files = produce(state.files, (draftFiles) => {
      const fileIndex = draftFiles.findIndex(
        (file) => file.id === updatedFile.id
      );
      if (fileIndex === -1) {
        return;
      }
      draftFiles[fileIndex] = updatedFile;
    });
  },
  addFileStipToDeal(state, { stipId, file }: { stipId: number; file: IFile }) {
    state.active = produce(state.active, (draftActive: IApplication) => {
      const stip = draftActive.stips.find((stip) => stip.id == stipId);
      if (!stip) {
        return;
      }
      if (!stip.files) {
        stip.files = [];
      }
      stip.files.push(file);
    });
  },
  addOffer(state, offer: IOffer) {
    state.offers = [...state.offers, offer];
  },
  updateOffer(state, updatedOffer: IOffer) {
    state.offers = produce(state.offers, (draftOffers: IOffer[]) => {
      const index = draftOffers.findIndex(
        (offer) => offer.id === updatedOffer.id
      );
      if (index !== -1) {
        draftOffers[index] = updatedOffer;
      }
    });
  },
  addFileToStip(state, { stipId, file }: { stipId: number; file: IFile }) {
    state.offers = produce(state.offers, (draftOffers: IOffer[]) => {
      draftOffers.forEach((offer) => {
        const stip = offer.stips.find((stip) => stip.id == stipId);
        if (stip) {
          stip.files?.push(file);
        }
      });
    });
  },
  removeFileFromStip(
    state,
    { stip_id, file_id }: { stip_id: number; file_id: number }
  ) {
    state.offers = produce(state.offers, (draftOffers: IOffer[]) => {
      draftOffers.forEach((offer) => {
        const stip = offer.stips.find((stip) => stip.id == stip_id);
        if (stip && stip.files?.find((file) => file.id == file_id)) {
          stip.files.splice(
            stip.files.findIndex((file) => file.id == file_id),
            1
          );
        }
      });
    });
  },
  removeStipFileFromDeal(
    state,
    { stip_id, file_id }: { stip_id: number; file_id: number }
  ) {
    state.active = produce(state.active, (draftActive: IApplication) => {
      const stip = draftActive.stips.find((stip) => stip.id == stip_id);
      if (stip && stip.files?.find((file) => file.id == file_id)) {
        stip.files.splice(
          stip.files.findIndex((file) => file.id == file_id),
          1
        );
      }
    });
  },
  setCount(state, countData: IApplicationCount) {
    state.count = countData;
  },
  assignFundingAdvisor(
    state,
    payload: { appId: string; userId: number | null; removeDeal: boolean }
  ) {
    let updatedApplications = state.all;
    if (payload.removeDeal) {
      updatedApplications = updatedApplications.filter(
        (application) => application.id !== payload.appId
      );
      state.all = updatedApplications;
      return;
    }

    const fundingAdvisor = state.fundingAdvisors.find(
      (item) => item.id === payload.userId
    );
    // update application
    updatedApplications = produce(state.all, (draft: IApplication[]) => {
      const index = draft.findIndex((app) => app.id === payload.appId);
      if (index !== -1 && fundingAdvisor && fundingAdvisor.full_name) {
        draft[index].advisor_full_name = fundingAdvisor.full_name;
        draft[index].funding_advisor = fundingAdvisor;
      }
    });
    state.all = updatedApplications;
    if (state.active && fundingAdvisor) {
      state.active.funding_advisor = fundingAdvisor;
    }
  },
  assignClientFundingAdvisor(
    state,
    {
      appId,
      clientId,
      userId
    }: { appId: string; clientId: number; userId: number }
  ) {
    const advisor = state.clientFundingAdvisors[clientId].find(
      (item) => item.id === userId
    );
    if (!advisor) {
      return;
    }
    state.all = produce(state.all, (draft: IApplication[]) => {
      const app = draft.find((app) => app.id === appId);
      if (app) {
        app.client_advisor_full_name = advisor.full_name;
        app.client_funding_advisor = advisor;
      }
    });
    if (state.active) {
      state.active.client_funding_advisor = advisor;
    }
  },
  removeFundingAdvisor(state, appId: string) {
    const app = state.all.find((app) => app.id === appId);
    if (app) {
      app.advisor_full_name = "";
      app.funding_advisor = null;
    }
    if (state.active) {
      state.active.funding_advisor = null;
    }
  },
  removeClientFundingAdvisor(state, appId: string) {
    const app = state.all.find((app) => app.id === appId);
    if (app) {
      app.client_advisor_full_name = "";
      app.client_funding_advisor = null;
    }
    if (state.active) {
      state.active.client_funding_advisor = null;
    }
  },
  assignUnderwriter(state, payload: { appId: string; userId: number }) {
    const underwriter = state.underwriters.find(
      (item) => item.id === payload.userId
    );
    if (!underwriter) {
      return;
    }
    state.all = produce(state.all, (draft: IApplication[]) => {
      const index = draft.findIndex((app) => app.id === payload.appId);
      if (index !== -1) {
        draft[index].underwriter_full_name = underwriter.full_name;
        draft[index].underwriter = underwriter;
      }
    });
    if (state.active) {
      state.active.underwriter = underwriter;
    }
  },
  assignClientUnderwriter(
    state,
    {
      appId,
      clientId,
      userId
    }: { appId: string; clientId: number; userId: number }
  ) {
    const underwriter = state.clientUnderwriters[clientId].find(
      (item) => item.id === userId
    );
    if (!underwriter) {
      return;
    }
    state.all = produce(state.all, (draft: IApplication[]) => {
      const app = draft.find((app) => app.id === appId);
      if (app) {
        app.client_underwriter_full_name = underwriter.full_name;
        app.client_underwriter = underwriter;
      }
    });
    if (state.active) {
      state.active.client_underwriter = underwriter;
    }
  },
  removeUnderwriter(state, appId: string) {
    const app = state.all.find((app) => app.id === appId);
    if (app) {
      app.underwriter_full_name = "";
      app.underwriter = null;
    }
    if (state.active) {
      state.active.underwriter = null;
    }
  },
  removeClientUnderwriter(state, appId: string) {
    const app = state.all.find((app) => app.id === appId);
    if (app) {
      app.client_underwriter_full_name = "";
      app.client_underwriter = null;
    }
    if (state.active) {
      state.active.client_underwriter = null;
    }
  },
  assignAnalyst(state, payload: { appId: string; userId: number }) {
    const analyst = state.analysts.find((item) => item.id === payload.userId);
    if (!analyst) {
      return;
    }
    state.all = produce(state.all, (draft: IApplication[]) => {
      const index = draft.findIndex((app) => app.id === payload.appId);
      if (index !== -1 && analyst && analyst.full_name) {
        draft[index].analyst_full_name = analyst.full_name;
        draft[index].analyst = analyst;
      }
    });
    if (state.active) {
      state.active.analyst = analyst;
    }
  },
  assignClientAnalyst(
    state,
    {
      appId,
      clientId,
      userId
    }: { appId: string; clientId: number; userId: number }
  ) {
    const analyst = state.clientAnalysts[clientId]?.find(
      (item) => item.id === userId
    );
    if (!analyst) {
      return;
    }
    state.all = produce(state.all, (draft: IApplication[]) => {
      const app = draft.find((app) => app.id === appId);
      if (app) {
        app.client_analyst_full_name = analyst.full_name;
        app.client_analyst = analyst;
      }
    });
    if (state.active) {
      state.active.client_analyst = analyst;
    }
  },
  removeAnalyst(state, appId: string) {
    const app = state.all.find((app) => app.id === appId);
    if (app) {
      app.analyst_full_name = "";
      app.analyst = null;
    }
    if (state.active) {
      state.active.analyst = null;
    }
  },
  removeClientAnalyst(state, appId: string) {
    const app = state.all.find((app) => app.id === appId);
    if (app) {
      app.client_analyst_full_name = "";
      app.client_analyst = null;
    }
    if (state.active) {
      state.active.client_analyst = null;
    }
  },
  setUpdatedDiscoveryCallAnswers(state, data: []) {
    state.active.discovery_call_answers = data;
  },
  setCommercialData(state, data: ICommercialData) {
    const {
      commercial_data,
      statuses,
      business_legal_name,
      dates,
      request_data
    } = data;

    state.active.commercialData =
      commercial_data as unknown as IApplicationsStateCommercialData;
    state.active.commercialData.statuses = statuses;
    state.active.commercialData.business_legal_name = business_legal_name;
    state.active.commercialData.dates = dates;
    state.active.commercialData.request_data = request_data;
  },
  setPersonalInfo(state, updatedOwner: IOwner) {
    if (state.active.personal_information?.id === updatedOwner.id) {
      state.active.personal_information = {
        ...state.active.personal_information,
        ...updatedOwner
      };
      if (state.active.personal_information.address) {
        state.active.personal_information.address.is_primary = true;
      }
      return;
    }
    if (
      state.active.business.other_owners?.some(
        (owner) => owner.id === updatedOwner.id
      )
    ) {
      const index = state.active.business.other_owners.findIndex(
        (owner) => owner.id === updatedOwner.id
      );
      state.active.business.other_owners[index] = {
        ...state.active.business.other_owners[index],
        ...updatedOwner
      };
      if (state.active.business.other_owners[index]?.address) {
        state.active.business.other_owners[index].address.is_primary = true;
      }
    }
  },
  setPiis(state, piis: IPii[]) {
    if (state.active.personal_information) {
      state.active.personal_information.piis = piis;
    }
  },
  setTaxExempt(state, tax_exempt: number) {
    state.active.business.tax_exempt = tax_exempt;
  },
  addFilesToActiveApp(state, filesData: IFile[]) {
    state.files = [...filesData, ...state.files];

    const allOwners = [
      state.active.personal_information,
      ...(state.active.business?.other_owners || [])
    ];

    filesData.forEach((file) => {
      if (file.file_type !== SIGNED_DOC_FILE_TYPE) {
        return;
      }
      const owner = allOwners.find(
        (owner) => owner?.id === file.personal_information?.id
      );
      owner?.signed_docs.push({
        file_id: file.id,
        url: file.file_url,
        product_id: file.product_id || null,
        signature_status: 2
      });
    });
  },
  setBillingInfo(state, billingInfo: IBillingInformation) {
    state.active.billing_information = billingInfo;
  },
  updateDiscoveryState(state, discoveryState) {
    state.active.is_discovery_complete = !!discoveryState;
  },
  updateEquipmentShareUse(state, equipmentShareUse: IEquipmentRentalApproval) {
    state.active.equipment_rental_approval = equipmentShareUse;
  },
  setOffersLoading(state, value: boolean) {
    state.offersLoading = value;
  },
  saveClientPreferences(
    state,
    preferences: { list_order: []; daily_monthly_stat_order: [] }
  ) {
    state.clientPreferences = preferences;
  },
  setMoneythumbAnalytics(state, analytics) {
    state.moneythumbAnalytics = analytics;
  },
  setMoneythumbCfaAccountNumber(state, accountNumber) {
    state.moneythumbCfaAccountNumber = accountNumber;
  },
  setLoading(state, val: boolean) {
    state.loading = val;
  },
  setActiveAppAttributes(state, payload: IApplicationAttribute[]) {
    state.currentAppAttributes = payload;
  },
  setApplicationStatus(state, status: WorkflowSnapshotStatus) {
    state.active.workflow_snapshot.workflow_status = status;
  },
  updatePlacementPending(
    state,
    { id, status }: { id: ILenderPlacement["placement_id"]; status: string }
  ) {
    state.placements = produce(
      state.placements,
      (draftPlacements: ILenderPlacement[]) => {
        const placementId = draftPlacements.findIndex(
          (place) => place.placement_id === id
        );
        if (placementId === -1) {
          return;
        }
        draftPlacements[placementId].declinePending = status === "Declined";
      }
    );
  },
  updateActiveWorkflowStage(state, stageId) {
    state.active.stage_id = stageId;
  },
  setFilters(state, payload: ISmartFilter[]) {
    state.filters = payload;
  },
  setSmartviewBuilderOpen(state, val: boolean) {
    state.smartviewBuilderOpen = val;
  },
  setSmartviewBuilderModel(state, model: ISmartFilter) {
    state.smartviewBuilderModel = model;
  },
  setActiveFilterId(state, id: number) {
    state.activeFilterId = id;
  },
  setActiveSmartviewCategory(state, category: SmartviewCategory) {
    state.activeSmartviewCategory = category;
  },
  unsetDeletedFilter(state, id: number) {
    state.filters = state.filters.filter((f) => f.id !== id);
  },
  appendNewFilter(state, filter: ISmartFilter) {
    state.filters = [...state.filters, filter];
  },
  appendUpdatedFilter(state, newFilter: ISmartFilter) {
    const filterIndex = state.filters.findIndex((f) => f.id === newFilter.id);
    if (filterIndex === -1) {
      return;
    }
    state.filters[filterIndex] = {
      ...state.filters[filterIndex],
      ...newFilter
    };
  },
  setMetrics(state, metrics: IApplicationMetrics) {
    state.metrics = metrics;
  },
  setDealMode(state, { id, data }: { id: string; data: DealMode }) {
    // update mode on deal view
    if (state.active.id === id) {
      state.active.deal_mode = data;
      return;
    }

    // update mode on deals lost view
    const index = state.all.findIndex((app) => app.id === id);
    if (index === -1) {
      return;
    }

    state.all = produce(state.all, (draft) => {
      draft[index] = {
        ...draft[index],
        deal_mode: data
      };
    });
  },
  setPhoneStatus(
    state,
    payload: {
      applicationId: string;
      phone: Omit<DealTelephone, "is_primary"> & { name: string };
      isBusiness: boolean;
    }
  ) {
    const updatePhoneStatus = (phone: DealTelephone) => {
      if (phone.number === payload.phone.number) {
        return {
          ...phone,
          status: payload.phone.status
        };
      }
      return phone;
    };

    // update mode on deal view
    if (state.active.id === payload.applicationId) {
      state.active = produce(state.active, (draft) => {
        if (payload.isBusiness) {
          if (!draft.business) {
            return;
          }
          draft.business.all_telephones =
            draft.business.all_telephones.map(updatePhoneStatus);
          return;
        }
        if (!draft.personal_information) {
          return;
        }
        draft.personal_information.all_telephones =
          draft.personal_information.all_telephones.map(updatePhoneStatus);

        if (!draft.business) {
          return;
        }

        draft.business.other_owners = draft.business?.other_owners?.map(
          (owner) => {
            if (!owner.all_telephones) {
              return owner;
            }
            return {
              ...owner,
              all_telephones: owner.all_telephones?.map(updatePhoneStatus)
            };
          }
        );
      });
      return;
    }

    // update mode on deals list view
    const index = state.all.findIndex(
      (app) => app.id === payload.applicationId
    );

    if (index === -1) {
      return;
    }

    state.all = produce(state.all, (draft) => {
      if (payload.isBusiness) {
        if (!draft[index].business) {
          return;
        }
        draft[index] = {
          ...draft[index],
          business: {
            ...draft[index].business,
            all_telephones:
              draft[index].business.all_telephones.map(updatePhoneStatus)
          }
        };
        return;
      }

      if (!draft[index].personal_information) {
        return;
      }

      draft[index] = {
        ...draft[index],
        personal_information: {
          ...draft[index].personal_information,
          all_telephones:
            draft[index].personal_information.all_telephones.map(
              updatePhoneStatus
            )
        }
      };
    });
  },
  setFundingTransactions(state, payload: Record<string, ITransaction[]>) {
    state.fundingTransactions = {
      ...state.fundingTransactions,
      ...payload
    };
  },
  setSystemPreferences(state, data: ISystemPreferences) {
    state.systemPreferences = data;
  },
  setDealVisibilityForRole(state, data: IDealVisibilityUpdate) {
    const { role, settings } = data;
    state.systemPreferences.deal_visibility = {
      ...state.systemPreferences.deal_visibility,
      [role]: settings
    };
  },
  setBankData(state, data: IBankData) {
    state.bankData = data;
  },
  setPlaidData(state, data: PlaidData) {
    state.plaidData = data;
  },
  setPlaidDataLoading(state, value: boolean) {
    state.plaidDataLoading = value;
  },
  setClientTrackingTokens(state, data: string[]) {
    state.clientTrackingTokens = data;
  },
  setSelectedAffiliatedBusinessId(state, id: number) {
    state.selectedAffiliatedBusinessId = id;
  },
  setSelectedAffiliatedIndividualId(state, id: number) {
    state.selectedAffiliatedIndividualId = id;
  },
  setAdditionalTrackingTokens(state, data: string[]) {
    state.additionalTrackingTokens = data;
  },
  setPrimaryScorecard(state, scorecardId: string) {
    state.active.score_card_id = scorecardId;
  },
  setPrimaryScorecardGroup(state, scorecardGroupId: string) {
    state.active.score_card_group_id = scorecardGroupId;
  },
  setWidgetInfo(state, data: WidgetInfo) {
    state.widgetInfo = data;
  },
  updateApplicationDataInList(
    state,
    payload: {
      application_id: IApplication["id"];
      path: string;
      value: Partial<IApplication>;
    }
  ) {
    state.all = produce(state.all, (draftApplications) => {
      const index = draftApplications.findIndex(
        (app) => app.id === payload.application_id
      );
      if (index === -1) {
        return;
      }
      set(draftApplications[index], payload.path, payload.value);
    });
  },
  setDealsDoStateToProcessing(state, applications: string[]) {
    state.all = produce(state.all, (draftApplications) => {
      applications.forEach((application) => {
        const index = draftApplications.findIndex(
          (app) => app.id === application
        );
        if (index !== -1) {
          // set status to processing till we get a response or a page is reloaded
          const tempLogState = {
            ...(draftApplications[index].data_orchestration_log || {}),
            status: 1,
            master_status: 2
          } as IApplication["data_orchestration_log"];
          draftApplications[index] = {
            ...draftApplications[index],
            data_orchestration_log: tempLogState
          };
        }
      });
    });
  },
  setFiles(state, files: IFile[]) {
    state.files = files;
  },

  setOfferNote(
    state,
    { offerId, note }: { offerId: IOffer["id"]; note: OfferNote }
  ) {
    state.offers = produce(state.offers, (draftOffers: IOffer[]) => {
      const offerIndex = draftOffers.findIndex((offer) => offer.id === offerId);
      if (offerIndex === -1) {
        return;
      }
      draftOffers[offerIndex].note = note;
    });
  },

  removeOfferFromNote(state, offerId: IOffer["id"]) {
    state.offers = produce(state.offers, (draftOffers: IOffer[]) => {
      const offerIndex = draftOffers.findIndex((offer) => offer.id === offerId);
      if (offerIndex === -1) {
        return;
      }
      draftOffers[offerIndex].note = null;
    });
  },
  setWorkflowStatuses(
    state,
    {
      statuses,
      applicationId
    }: { statuses: IWorkflowStatus[]; applicationId: string }
  ) {
    state.workflow_statuses[applicationId] = statuses;
  }
};

const actions: ActionTree<IApplicationsState, IRootState> = {
  async getAll({ commit, getters }, payload: Record<string, unknown>) {
    commit("setLoading", true);
    const data = await applicationsApiService.getApplications(
      payload,
      getters["isSearchIndexed"]
    );
    commit("setMetadata", data.meta);
    commit("setAll", data.data);
    commit("setLoading", false);
  },
  async syncActiveToAll({ state, commit }) {
    const activeDealId = state.active.id ?? "";
    if (!activeDealId) {
      return;
    }
    const dealToUpdate = state.all.find(({ id }) => id === activeDealId);
    if (!dealToUpdate) {
      return;
    }
    const {
      data: [updatedDeal]
    } = await applicationsApiService.getApplications({
      search: activeDealId
    });
    if (updatedDeal?.id !== dealToUpdate.id) {
      return;
    }

    commit(
      "setAll",
      state.all.map((deal) => (deal.id === activeDealId ? updatedDeal : deal))
    );
  },
  async getWorkflowStatuses({ commit }, applicationId: string) {
    const statuses: IWorkflowStatus[] =
      await workflowApiService.getWorkflowStatuses(applicationId);

    commit("setWorkflowStatuses", { statuses, applicationId });
  },
  async getAllWithSameParams({ commit, dispatch, state, getters }) {
    if (state.loading) {
      return;
    }
    const params = queryString.parse(location.search, {
      arrayFormat: "bracket"
    });

    if (state.meta.current_page === 1) {
      const data = await applicationsApiService.getApplications(
        params,
        getters["isSearchIndexed"]
      );
      commit("setMetadata", data.meta);
      commit("setAll", data.data);
    }
    dispatch("getCount", params);
  },
  async getApplication({ commit }, appId: string) {
    const application = await applicationsApiService.getApplication(appId);
    commit("setActive", application);
  },
  setDealHistoryStack({ state, commit }, appId: string) {
    const activeIndex = state.all.findIndex((deal) => deal.id == appId);
    if (activeIndex !== -1) {
      commit("setPrevious", state.all?.[activeIndex - 1]?.id ?? "");
    } else {
      commit("setPrevious", "");
    }
    if (activeIndex !== state.all.length - 1) {
      commit("setNext", state.all?.[activeIndex + 1]?.id ?? "");
    } else {
      commit("setNext", "");
    }
  },
  async find(
    { dispatch, commit },
    { appId, fetchOffers = true }: { appId: string; fetchOffers: boolean }
  ) {
    commit("setLoading", true);
    commit("setPlacements", []);
    commit("setOffers", []);

    try {
      const data = await applicationsApiService.getApplication(appId);
      commit("setActive", data);

      if (fetchOffers) {
        await dispatch("getOffers");
      }
      dispatch("setDealHistoryStack", appId);
      return data;
    } finally {
      commit("setLoading", false);
    }
  },
  async getFundingAdvisors(
    { commit },
    payload?: { ignoreAssignedDeals?: boolean }
  ) {
    const servicePayload: GetUsersByRoleParams = { role: FUNDING_ADVISOR };
    if (payload?.ignoreAssignedDeals !== undefined) {
      servicePayload.ignore_assigned_deals = booleanToBinary(
        payload.ignoreAssignedDeals
      );
    }
    const data = await usersApiService.getUsersByRole(servicePayload);
    commit("setFundingAdvisors", data);
  },
  async getClientFundingAdvisors(
    { commit },
    payload?: { clientId?: IClient["id"]; ignoreAssignedDeals?: boolean }
  ) {
    const servicePayload: GetUsersByRoleParams = {
      role: CLIENT_FUNDING_ADVISOR
    };
    if (payload?.ignoreAssignedDeals !== undefined) {
      servicePayload.ignore_assigned_deals = booleanToBinary(
        payload.ignoreAssignedDeals
      );
    }
    if (payload?.clientId) {
      servicePayload.client_id = payload.clientId;
    }
    const data = await usersApiService.getUsersByRole(servicePayload);

    commit("setClientFundingAdvisors", {
      clientId: payload?.clientId,
      advisors: data
    });
  },
  async assignFundingAdvisor(
    { commit },
    payload: { appId: string; userId: number }
  ) {
    await applicationsApiService.assignUser(payload.appId, {
      funding_advisor_id: payload.userId
    });
    commit("assignFundingAdvisor", payload);
  },
  async duplicateDeal(
    _,
    payload: { data: Record<string, string>; id: string }
  ) {
    return await applicationsApiService.duplicateDeal(payload.id, payload.data);
  },
  async assignClientFundingAdvisor(
    { commit },
    payload: { appId: string; userId: number; clientId: number }
  ) {
    await applicationsApiService.assignUser(payload.appId, {
      client_funding_advisor_id: payload.userId
    });
    commit("assignClientFundingAdvisor", payload);
  },
  async removeFundingAdvisor({ commit }, appId: string) {
    await applicationsApiService.assignUser(appId, {
      funding_advisor_id: null
    });
    commit("removeFundingAdvisor", appId);
  },
  async removeClientFundingAdvisor({ commit }, appId: string) {
    await applicationsApiService.assignUser(appId, {
      client_funding_advisor_id: null
    });
    commit("removeClientFundingAdvisor", appId);
  },
  async assignUnderwriter(
    { commit },
    payload: { userId: number; appId: string }
  ) {
    await applicationsApiService.assignUser(payload.appId, {
      underwriter_id: payload.userId
    });
    commit("assignUnderwriter", payload);
  },
  async assignClientUnderwriter(
    { commit },
    payload: { appId: string; userId: number; clientId: number }
  ) {
    await applicationsApiService.assignUser(payload.appId, {
      client_underwriter_id: payload.userId
    });
    commit("assignClientUnderwriter", payload);
  },
  async removeUnderwriter({ commit }, appId: string) {
    await applicationsApiService.assignUser(appId, {
      underwriter_id: null
    });
    commit("removeUnderwriter", appId);
  },
  async removeClientUnderwriter({ commit }, appId: string) {
    await applicationsApiService.assignUser(appId, {
      client_underwriter_id: null
    });
    commit("removeClientUnderwriter", appId);
  },
  async assignAnalyst({ commit }, payload: { appId: string; userId: number }) {
    await applicationsApiService.assignUser(payload.appId, {
      analyst_id: payload.userId
    });
    commit("assignAnalyst", payload);
  },
  async assignClientAnalyst(
    { commit },
    payload: { appId: string; userId: number; clientId: number }
  ) {
    await applicationsApiService.assignUser(payload.appId, {
      client_analyst_id: payload.userId
    });
    commit("assignClientAnalyst", payload);
  },
  async removeAnalyst({ commit }, appId: string) {
    await applicationsApiService.assignUser(appId, {
      analyst_id: null
    });
    commit("removeAnalyst", appId);
  },
  async removeClientAnalyst({ commit }, appId: string) {
    await applicationsApiService.assignUser(appId, {
      client_analyst_id: null
    });
    commit("removeClientAnalyst", appId);
  },
  async getUnderwriters(
    { commit },
    payload?: { ignoreAssignedDeals?: boolean }
  ) {
    const servicePayload: GetUsersByRoleParams = { role: UNDERWRITER };
    if (payload?.ignoreAssignedDeals !== undefined) {
      servicePayload.ignore_assigned_deals = booleanToBinary(
        payload.ignoreAssignedDeals
      );
    }
    const data = await usersApiService.getUsersByRole(servicePayload);
    commit("setUnderwriters", data);
  },
  async getClientUnderwriters(
    { commit },
    payload?: { clientId?: IClient["id"]; ignoreAssignedDeals?: boolean }
  ) {
    const servicePayload: GetUsersByRoleParams = { role: CLIENT_UNDERWRITER };
    if (payload?.ignoreAssignedDeals !== undefined) {
      servicePayload.ignore_assigned_deals = booleanToBinary(
        payload.ignoreAssignedDeals
      );
    }
    if (payload?.clientId) {
      servicePayload.client_id = payload.clientId;
    }
    const data = await usersApiService.getUsersByRole(servicePayload);

    commit("setClientUnderwriters", {
      clientId: payload?.clientId,
      underwriters: data
    });
  },
  async getAnalysts({ commit }, payload?: { ignoreAssignedDeals?: boolean }) {
    const servicePayload: GetUsersByRoleParams = { role: ANALYST };
    if (payload?.ignoreAssignedDeals !== undefined) {
      servicePayload.ignore_assigned_deals = booleanToBinary(
        payload.ignoreAssignedDeals
      );
    }
    const data = await usersApiService.getUsersByRole(servicePayload);
    commit("setAnalysts", data);
  },
  async getClientAnalysts(
    { commit },
    payload?: { clientId?: IClient["id"]; ignoreAssignedDeals?: boolean }
  ) {
    const servicePayload: GetUsersByRoleParams = {
      role: CLIENT_ANALYST
    };
    if (payload?.ignoreAssignedDeals !== undefined) {
      servicePayload.ignore_assigned_deals = booleanToBinary(
        payload.ignoreAssignedDeals
      );
    }
    if (payload?.clientId) {
      servicePayload.client_id = payload.clientId;
    }
    const data = await usersApiService.getUsersByRole(servicePayload);

    commit("setClientAnalysts", {
      clientId: payload?.clientId,
      analysts: data
    });
  },
  async enrichApp({ state, commit, dispatch }, payload) {
    await applicationsApiService.enrichApp(state.active.id, payload);
    await dispatch("getCommercialData", { id: state.active.id });
    const data = await applicationsApiService.getApplication(state.active.id);
    commit("setActive", data);
  },
  async enrichBCApp({ state, commit, dispatch }, payload) {
    await applicationsApiService.enrichBCApp(state.active.id, payload);
    await dispatch("getCommercialData", { id: state.active.id });
    const data = await applicationsApiService.getApplication(state.active.id);
    commit("setActive", data);
  },
  async killActiveDeal(
    { dispatch, state, commit },
    payload: Record<string, string>
  ) {
    try {
      commit("setLoading", true);
      await applicationsApiService.deadApplication(state.active.id, payload);
      await dispatch("find", { appId: state.active.id });
      dispatch("syncActiveToAll");
    } finally {
      commit("setLoading", false);
    }
  },
  async sendDeadEmail({ state }) {
    return await applicationsApiService.sendDeadEmail(state.active.id);
  },
  async reviveActiveApp({ dispatch, state }) {
    await applicationsApiService.reviveApplication(state.active.id);
    await dispatch("find", { appId: state.active.id });
    dispatch("syncActiveToAll");
  },
  async updateActiveBusinessInfo(
    { commit, state },
    businessInfo: IApplication
  ) {
    const appInfo: Partial<IApplication> = {
      naics_code: businessInfo.naics_code,
      industry: businessInfo.industry,
      business_notes: businessInfo.business_notes || null
    };

    if (businessInfo.funds_needed_time) {
      appInfo.funds_needed_time = businessInfo.funds_needed_time;
    }

    if (businessInfo.use_of_fund_id) {
      appInfo.use_of_fund_id = businessInfo.use_of_fund_id;
    }

    const updatedBusinessInfo =
      await applicationsApiService.updateBusinessInfoForDeal(
        businessInfo,
        state.active.id
      );

    const updatedAppInfo = await applicationsApiService.updateAppInfo(
      appInfo,
      state.active.id
    );

    commit("setActive", updatedAppInfo);
    commit("setActiveBusinessInfo", updatedBusinessInfo);
  },
  async updateActivePersonalInfo(
    { commit, state },
    { owner }: { owner: IOwner }
  ) {
    commit("setLoading", true);
    try {
      const data = await applicationsApiService.updatePersonalInfo(
        owner,
        state.active.id,
        owner.id
      );
      commit("setPersonalInfo", data);

      if (data.piis) {
        commit("setPiis", data.piis);
      }
    } finally {
      commit("setLoading", false);
    }
  },
  async updatePiis({ state, commit }, data: { tax_exempt: number }) {
    await applicationsApiService.updateAppPiis(state.active.id, data);
    if (data.tax_exempt) {
      commit("setTaxExempt", data.tax_exempt == 1);
    }
  },
  async updateEquipmentShareUse({ state, commit }, equipmentShareUse) {
    await applicationsApiService.updateEquipmentShareUse(
      state.active.id,
      equipmentShareUse
    );
    commit("updateEquipmentShareUse", equipmentShareUse);
  },
  async getPlacements({ state, commit }) {
    const placements = await applicationsApiService.getPlacements(
      state.active.id
    );
    commit("setPlacements", placements);
  },
  async createPlacement({ state }, data: Partial<ILenderPlacement>) {
    const { placed } = await applicationsApiService.createPlacement(
      state.active.id,
      data
    );
    return { ...data, placed } as ILenderPlacement & { placed: boolean };
  },
  async updatePlacementStatus(
    { commit, dispatch },
    { applicationId, data }: { applicationId: string; data: ILenderPlacement }
  ) {
    commit("setOffersLoading", true);
    try {
      const updatedPlacement =
        await applicationsApiService.updatePlacementStatus(applicationId, data);
      commit("setUpdatedPlacement", updatedPlacement);
      await dispatch("getOffers");
    } finally {
      commit("setOffersLoading", false);
    }
  },
  async uploadFile({ state, commit }, data: IFilePayload) {
    const file = await applicationsApiService.uploadApplicationFile(
      state.active.id,
      data
    );
    commit(
      "setGlobalMessage",
      { title: "File uploaded successfully!", type: "success" },
      { root: true }
    );
    if (data.offer_stip_id) {
      commit("addFileToStip", { stipId: data.offer_stip_id, file });
    } else if (data.application_stip_id) {
      commit("addFileStipToDeal", { stipId: data.application_stip_id, file });
    }
    return file;
  },
  async sendStipsToFunder(_, data: ISendStipsToFunderPayload) {
    return await applicationsApiService.sendStipsToFunder(data.offer_id, data);
  },
  async sendContractByEmail({ state }) {
    try {
      await applicationsApiService.sendContractByEmail(state.active.id);
      return true;
    } catch {
      return false;
    }
  },
  async sendFundingContract(
    { state },
    {
      applicationId,
      contractTemplateId
    }: { applicationId?: string; contractTemplateId?: string }
  ) {
    await applicationsApiService.sendFundingContract(
      applicationId ?? state.active.id,
      contractTemplateId
    );
  },
  async updateOfferStips(
    { commit, state },
    { id, stips }: { id: string; stips: StipPayload[] }
  ) {
    const offer = await applicationsApiService.updateOfferStips(id, stips);

    const offers = state.offers.map((item) => {
      return item.id == id
        ? {
            ...item,
            ...offer
          }
        : item;
    });

    commit("setOffers", offers);
  },
  async updateApplicationStips(
    { commit, state },
    { id, stips }: { id: string; stips: StipPayload[] }
  ) {
    const newStips = await applicationsApiService.updateApplicationStips(
      id,
      stips
    );

    commit("setActive", { ...state.active, stips: newStips });
  },
  async updateOfferStatus(
    { commit, dispatch },
    { id, status, reason }: { id: string; status: string; reason?: string }
  ) {
    await applicationsApiService.updateOfferStatus(id, status, reason);
    commit("setOfferStatus", { id, status });
    await dispatch("getOffers");
  },
  async updateClosingStatus({ commit }, status: string) {
    await applicationsApiService.updateClosingStatus(state.active.id, status);
    commit("setClosingStatus", status);
  },
  async getOffers({ state, commit }) {
    if (!state.active.id) {
      return;
    }
    commit("setOffersLoading", true);
    try {
      const offers = await applicationsApiService.getOffers(state.active.id);
      commit("setOffers", offers);
    } finally {
      commit("setOffersLoading", false);
    }
  },
  async sendToLoanProvider(
    { dispatch },
    {
      offerId,
      servicer,
      payload
    }: { offerId: string; servicer: string; payload: Record<string, string> }
  ) {
    await applicationsApiService.sendToLoanProvider(offerId, servicer, payload);
    await dispatch("getOffers");
  },
  async saveOffer(
    { dispatch, commit },
    { offer, mode = "save" }: { offer: IOffer; mode: editOfferMode }
  ) {
    const offerServiceMap = {
      save: applicationsApiService.saveOffer,
      update: applicationsApiService.updateOffer
    };
    const offerResponse = await offerServiceMap[mode](
      formatPayloadWithCurrency(offer as unknown as Record<string, string>),
      offer.application_id
    );
    dispatch("syncActiveToAll");
    commit(
      "setGlobalMessage",
      { title: "Offer saved!", type: "success" },
      { root: true }
    );
    await dispatch("getOffers");
    return offerResponse;
  },
  async saveSelfFundedOffer(
    { dispatch, commit },
    { offer, mode = "save" }: { offer: IOffer; mode: editOfferMode }
  ) {
    const offerServiceMap = {
      save: applicationsApiService.saveSelfFundedOffer,
      update: applicationsApiService.updateOffer
    };
    const offerResponse = await offerServiceMap[mode](
      formatPayloadWithCurrency(offer as unknown as Record<string, string>),
      offer.application_id
    );
    dispatch("syncActiveToAll");
    commit(
      "setGlobalMessage",
      { title: "Offer saved!", type: "success" },
      { root: true }
    );
    await dispatch("getOffers");
    return offerResponse;
  },
  async confirmOffer(
    { dispatch },
    { id, offer }: { id: string; offer: Partial<IOffer> }
  ) {
    await applicationsApiService.confirmOffer(
      id,
      formatPayloadWithCurrency(offer as Record<string, string | number>)
    );
    await dispatch("getOffers");
    dispatch("syncActiveToAll");
  },
  async deleteOffer({ commit }, { offer }: { offer: IOffer }) {
    await applicationsApiService.deleteOffer(offer.application_id, offer.id);
    commit(
      "setGlobalMessage",
      { title: "Offer Deleted", type: "success" },
      { root: true }
    );
  },
  async updateContactedVia(_, payload: { offer_ids: string[]; type: string }) {
    return await applicationsApiService.updateContactedVia(
      state.active.id,
      payload
    );
  },
  async sendOffersEmail(
    { commit },
    {
      applicationId,
      offers = null
    }: { applicationId: string; offers: string[] | null }
  ) {
    if (offers?.length) {
      offers.forEach((offerId) =>
        commit("setOfferStatus", { id: offerId, status: 2 })
      );
    }
    const responseMessage = {
      title: "Email sent successfully",
      type: "success"
    };
    try {
      await applicationsApiService.sendOffersEmail(applicationId, offers);
    } catch (error) {
      responseMessage.title =
        (
          error as AxiosError<{
            data: {
              message: string;
            };
          }>
        )?.response?.data.data.message ?? i18n.global.t("COMMON.GENERIC_ERROR");
      responseMessage.type = "error";
    } finally {
      commit("setGlobalMessage", responseMessage, { root: true });
    }
  },

  async attachOfferNote(
    { state, commit },
    { offerId, note }: { offerId: IOffer["id"]; note: OfferNote["note"] }
  ) {
    const offer = state.offers.find((offer) => offer.id === offerId);
    const noteExists = !!offer?.note;
    const resourceAction = noteExists
      ? applicationsApiService.updateOfferNote
      : applicationsApiService.createOfferNote;
    const attachedNote = await resourceAction(offerId, { note });
    commit("setOfferNote", { offerId, note: attachedNote });
  },

  async deleteOfferNote({ commit }, { offerId }: { offerId: IOffer["id"] }) {
    await applicationsApiService.deleteOfferNote(offerId);
    commit("removeOfferFromNote", offerId);
  },

  async deleteFile({ commit }, data: { id: number }) {
    const response = await applicationsApiService.deleteFile(data.id);
    if (response.status === 204) {
      commit("handleDeletedDocument", data.id);
    }
  },
  async deleteFiles({ commit }, payload: { ids: number[] }) {
    try {
      await applicationsApiService.deleteFiles(payload);
      payload.ids.forEach((id: number) => commit("handleDeletedDocument", id));
    } catch {
      commit("setGlobalMessage", i18n.global.t("COMMON.GENERIC_ERROR"), {
        root: true
      });
    }
  },
  async updateFile(
    { commit },
    payload: { id: number; data: Partial<Omit<IFile, "id">> }
  ) {
    const data = await applicationsApiService.updateFile(
      payload.id,
      payload.data
    );
    commit("setUpdatedFile", data);
  },
  async getCount({ commit, getters }, params: Record<string, number>) {
    const data = await applicationsApiService.getApplicationsCount(
      params,
      getters["isSearchIndexed"]
    );
    commit("setCount", data);
  },
  async setPrimaryScorecard(
    { commit, rootState, state },
    payload: { scorecardId: string; type: ScorecardType }
  ) {
    await applicationsApiService.setPrimaryScorecard(
      payload.scorecardId,
      state.active?.id,
      rootState.workflows.activeStage?.id,
      payload.type
    );
    const mutation =
      payload.type === ScorecardType.CARD
        ? "setPrimaryScorecard"
        : "setPrimaryScorecardGroup";
    commit(mutation, payload.scorecardId);
  },
  async updateDiscoveryCallAnswers(
    { state, commit },
    payload: IDiscoveryCallAnswer[]
  ) {
    const data = await applicationsApiService.updateDiscoveryCallAnswers(
      state.active.id,
      payload
    );
    commit("setUpdatedDiscoveryCallAnswers", data);
  },
  async addDiscoveryCallQuestion({ state }, payload: IDiscoveryCallQuestion) {
    const response = await applicationsApiService.createDiscoveryCallQuestions(
      state.active.id,
      payload
    );
    state.active.discovery_call_questions?.push(response);
  },
  async deleteDiscoveryCallQuestion({ state }, questionId: string) {
    await applicationsApiService.deleteDiscoveryCallQuestion(
      state.active.id,
      questionId
    );
    if (state.active.discovery_call_questions) {
      state.active.discovery_call_questions =
        state.active.discovery_call_questions.filter(
          (question) => question.id !== questionId
        );
    }
  },
  async getCommercialData({ commit }, payload: { id: string }) {
    const data = await applicationsApiService.getCommercialData(payload.id);
    commit("setCommercialData", data);
  },
  async generateBankAccountDocuments(
    _,
    payload: { accounts: IBankAccount[]; appId: string }
  ) {
    return await applicationsApiService.generateBankDocuments(
      payload.accounts,
      payload.appId
    );
  },
  async getResumeAppLink(
    { state },
    payload?: Partial<IApplicationTemporaryLinks>
  ) {
    return await applicationsApiService.getResumeAppLink(
      payload?.application_id ?? state.active.id,
      payload ? omit(payload, "application_id") : undefined
    );
  },

  async getBankStatementLink(
    { state },
    payload?: Partial<IApplicationTemporaryLinks>
  ) {
    return await applicationsApiService.getBankStatementLink(
      payload?.application_id ?? state.active.id
    );
  },

  async deleteApplication({ state }) {
    const id = state.active.id;
    if (!id) {
      return;
    }
    const response = await applicationsApiService.deleteApplication(id);
    const deletedDealIndex = state.all.findIndex((deal) => deal.id === id);
    if (deletedDealIndex !== -1) {
      state.all.splice(deletedDealIndex, 1);
    }
    return response;
  },
  async generateDocuSignLink(_, payload: Partial<IApplicationTemporaryLinks>) {
    return await applicationsApiService.generateDocuSignLink(
      payload.application_id ?? state.active.id,
      omit(payload, "application_id")
    );
  },
  async generateApplicantInfoWithDocuSignLink(
    _,
    payload: Partial<IApplicationTemporaryLinks>
  ) {
    return await applicationsApiService.generateApplicantInfoWithDocuSignLink(
      payload.application_id ?? state.active.id,
      omit(payload, "application_id")
    );
  },
  async generatePlaidLink(_, payload?: Partial<IApplicationTemporaryLinks>) {
    return await applicationsApiService.generatePlaidLink(
      payload?.application_id ?? state.active.id
    );
  },
  async generateRailzWidgetLink(
    _,
    payload?: Partial<IApplicationTemporaryLinks>
  ) {
    return await applicationsApiService.generateRailzWidgetLink(
      payload?.application_id ?? state.active.id
    );
  },
  async generateCodatWidgetLink(
    _,
    payload?: Partial<IApplicationTemporaryLinks>
  ) {
    return await applicationsApiService.generateCodatWidgetLink(
      payload?.application_id ?? state.active.id
    );
  },
  async generatePersonalInfoLink(
    _,
    { ownerId, application_id }: { ownerId: number; application_id?: string }
  ) {
    return await applicationsApiService.generatePersonalInfoLink(
      application_id ?? state.active.id,
      ownerId
    );
  },
  async generateBorrowerPlatformLinkForDeal(
    _,
    { ownerId, application_id }: { ownerId: number; application_id?: string }
  ) {
    return await applicationsApiService.generateBorrowerPlatformDealLink(
      application_id ?? state.active.id,
      ownerId
    );
  },
  async updatePrimaryTrackingToken({ state, commit }, client_token: string) {
    const updatedAppInfo = await applicationsApiService.updateAppInfo(
      {
        client_tracking_token: client_token
      },
      state.active.id
    );
    commit("setActive", updatedAppInfo);
    return updatedAppInfo;
  },
  async addClientTrackingToken(
    { state, commit },
    data: { isPrimaryToken: boolean; client_token: string }
  ) {
    const payload = data.isPrimaryToken
      ? {
          client_tracking_token: data.client_token
        }
      : {
          tracking_tokens: [...state.active.tracking_tokens, data.client_token]
        };
    const updatedAppInfo = await applicationsApiService.updateAppInfo(
      payload,
      state.active.id
    );
    commit("setActive", updatedAppInfo);
    return updatedAppInfo;
  },
  async updateAdditionalToken(
    { state, commit },
    data: { tokenIdx: number; client_token: string }
  ) {
    let updatedTrackingTokens = [...state.active.tracking_tokens];
    if (data.client_token) {
      updatedTrackingTokens[data.tokenIdx] = data.client_token;
    } else {
      updatedTrackingTokens = updatedTrackingTokens.filter(
        (_, idx) => idx !== data.tokenIdx
      );
    }

    const updatedAppInfo = await applicationsApiService.updateAppInfo(
      { tracking_tokens: updatedTrackingTokens },
      state.active.id
    );

    const infoWithNewTrackingTokens = {
      ...updatedAppInfo,
      // api returns undefined instead of empty array
      tracking_tokens: Array.isArray(updatedAppInfo.tracking_tokens)
        ? updatedAppInfo.tracking_tokens
        : []
    };

    commit("setActive", infoWithNewTrackingTokens);
    return infoWithNewTrackingTokens;
  },
  async getClientTrackingTokens({ commit }) {
    const { data } = await clientsApiService.getAllClientsTrackingToken();
    commit("setClientTrackingTokens", data);
  },
  async getAdditionalTrackingTokens({ commit }) {
    const { data } = await clientsApiService.getAdditionalTrackingTokens();
    commit("setAdditionalTrackingTokens", data);
  },
  async deleteAdditionalToken({ dispatch }, tokenIdx: string) {
    await dispatch("updateAdditionalToken", { tokenIdx, client_token: null });
  },
  async getAdvancedApiData(
    { commit },
    {
      applicationId,
      bookId,
      params
    }: { applicationId: string; bookId: number; params: IAdvancedCfaPayload }
  ) {
    const response = await applicationsApiService.getAdvancedCFA(
      applicationId,
      bookId,
      params
    );
    commit("saveClientPreferences", response.meta);
    return response.data;
  },
  async getMoneythumbAnalytics(
    { commit },
    {
      applicationId,
      bookId,
      bank_accounts
    }: { applicationId: string; bookId: number; bank_accounts?: string[] }
  ) {
    const response = await applicationsApiService.getMoneythumbAnalytics(
      applicationId,
      bookId,
      bank_accounts
    );
    commit("setMoneythumbAnalytics", response);
    return response;
  },
  async saveCfaPreference(
    _,
    {
      customerId,
      params
    }: {
      customerId: string;
      params: Record<string, string | (string | number)[]>;
    }
  ) {
    return await applicationsApiService.saveCFAPreferences(customerId, params);
  },
  async createDocVerification(
    { state },
    payload: { data: FormData; additionalConfig?: Record<string, unknown> }
  ) {
    const additionalConfig = payload.additionalConfig || {};
    const docVerification = await applicationsApiService.createDocVerification(
      state.active.id,
      payload.data,
      additionalConfig
    );
    docVerification.report.id_class = payload.data.get("type") as string;
    state.active = {
      ...state.active,
      document_verifications: [
        docVerification,
        ...state.active.document_verifications
      ]
    };
    return docVerification;
  },
  async getApplicationAttributes({ commit, state }) {
    const data = await applicationsApiService.getApplicationAttributes(
      state.active.id
    );
    commit("setActiveAppAttributes", data);
  },

  async updateApplicationAttributeValue(
    { dispatch },
    payload: IApplicationAttribute
  ) {
    await applicationsApiService.updateApplicationAttributeValue(payload);
    dispatch("getApplicationAttributes");
  },
  async updateBankAccountsVisibility({ state }, payload: IBankAccount[]) {
    await applicationsApiService.updateBankAccountsVisibility(
      state.active.id,
      payload
    );
  },
  async getFilters({ commit }) {
    const data = await applicationsApiService.getApplicationFilters();
    commit("setFilters", data);
  },
  async deleteFilter({ commit }, id: number) {
    await applicationsApiService.deleteApplicationFilter(id);
    commit("unsetDeletedFilter", id);
  },
  async createFilter({ commit }, filter: ISmartFilter) {
    const data = await applicationsApiService.createApplicationFilter(filter);
    commit("appendNewFilter", data);
    commit("setActiveFilterId", data.id);
  },
  async updateFilter({ commit }, filter: ISmartFilter) {
    const updatedFilter = await applicationsApiService.updateApplicationFilter(
      filter.id,
      filter
    );
    commit("appendUpdatedFilter", updatedFilter);
  },
  async updateApplicationFilterSortOrder(_, data: IFilterSort) {
    await applicationsApiService.updateApplicationFilterSortOrder(data);
  },
  async updateApplicationFilterVisibility(_, data: IFilterVisibility) {
    await applicationsApiService.updateApplicationFilterVisibility(data);
  },
  async getMetrics({ commit }, appId: string) {
    const data = await applicationsApiService.getDealMetrics(appId);
    commit("setMetrics", data);
  },
  async createEquipmentRentalOffer(
    { state, commit },
    payload: Record<string, string>
  ) {
    const response = await applicationsApiService.createEquipmentRentalOffer(
      state.active.id,
      payload
    );

    commit("addOffer", response.data);
  },
  async editEquipmentRentalOffer(
    { state, commit },
    { payload, offerId }: { payload: Record<string, string>; offerId: string }
  ) {
    const response = await applicationsApiService.editEquipmentRentalOffer(
      state.active.id,
      offerId,
      payload
    );
    commit("updateOffer", response.data);
  },
  async executeDataOrchestrationTemplate(
    _,
    payload: { id: string; template_id: string }
  ) {
    const { id: dealId, template_id: dataOrchestrationTemplateId } = payload;
    await applicationsApiService.executeDataOrchestrationTemplate(
      dealId,
      dataOrchestrationTemplateId
    );
    amplitude.track(AmplitudeEvent.DataOrchestrationRun, {
      "Application ID": dealId,
      "Data Orchestration ID": dataOrchestrationTemplateId
    });
  },
  async updateApplicationDealMode(
    { commit, dispatch },
    payload: { id: string; deal_mode: DealMode }
  ) {
    const response = await applicationsApiService.updateApplicationDealMode(
      payload.id,
      { deal_mode: payload.deal_mode }
    );
    dispatch("syncActiveToAll");
    commit("setDealMode", {
      id: payload.id,
      data: payload.deal_mode,
      response
    });
  },
  async getFundingTransactions({ commit }, offerId: string) {
    const transactions =
      await applicationsApiService.getFundingTransactions(offerId);
    commit("setFundingTransactions", { [offerId]: transactions });
  },
  async getPlacementsForDeals(_, dealIds: string[]) {
    return await applicationsApiService.getPlacementsForDeals(dealIds);
  },
  async getOffersForDeals(_, dealIds: string[]) {
    return await applicationsApiService.getOffersForDeals(dealIds);
  },
  async addFundingTransaction(
    { dispatch },
    payload: {
      offerId: string;
      data: ITransactionPayload;
    }
  ) {
    const newTransaction = await applicationsApiService.addFundingTransaction(
      payload.offerId,
      payload.data
    );
    void dispatch("getFundingTransactions", payload.offerId);
    return newTransaction;
  },
  async updateFundingTransaction(
    { dispatch },
    payload: {
      offerId: string;
      transactionId: string;
      data: Partial<ITransactionPayload>;
    }
  ) {
    const { offerId, transactionId, data } = payload;
    const updatedTransaction =
      await applicationsApiService.updateFundingTransaction(
        offerId,
        transactionId,
        data
      );
    void dispatch("getFundingTransactions", offerId);
    return updatedTransaction;
  },
  async deleteFundingTransaction(
    { dispatch },
    { offerId, transactionId }: { offerId: string; transactionId: string }
  ) {
    await applicationsApiService.deleteFundingTransaction(
      offerId,
      transactionId
    );
    void dispatch("getFundingTransactions", offerId);
  },
  async getSystemPreferences({ commit }) {
    const data = await applicationsApiService.getSystemPreferences();
    commit("setSystemPreferences", data);
  },
  async setDiscoveryStatus({ dispatch, commit, state }, status) {
    await applicationsApiService.setDiscoveryStatus(state.active.id, status);
    dispatch("syncActiveToAll");
    commit("updateDiscoveryState", status);
  },
  async updateApp({ state, commit }, payload: Partial<IApplication>) {
    const data = await applicationsApiService.updateAppInfo(
      payload,
      state.active.id
    );
    commit("setActive", { ...state.active, ...data });
  },
  async updateDealVisibility({ commit }, payload: IDealVisibilityUpdate) {
    await applicationsApiService.updateDealVisibility(payload);
    commit("setDealVisibilityForRole", payload);
  },

  async getBankData({ commit }, payload: string) {
    const data = await applicationsApiService.getBankData(payload);
    commit("setBankData", data);
  },
  async getWidgetInfo({ commit }, payload: string) {
    const data = await applicationsApiService.getWidgetInfo(payload);
    commit("setWidgetInfo", data);
  },
  async getPlaidData({ commit }, payload: string) {
    const data = await applicationsApiService.getPlaidData(payload);
    commit("setPlaidData", data);
  },
  async generateTaxDetailsDocuSignPlaidLink(
    _,
    payload: Partial<IApplicationTemporaryLinks>
  ) {
    const response =
      await applicationsApiService.generateTaxDetailsDocuSignPlaidLink(
        payload.application_id ?? state.active.id,
        Object.fromEntries(
          Object.entries(payload).filter(([key]) => key !== "application_id")
        )
      );
    return response.tax_details_consent_bank_statements;
  },
  async getFiles({ commit }, appId: string) {
    const data = await applicationsApiService.getFiles(appId);
    commit("setFiles", data);
  },
  async toggleShowOffer({ dispatch }, offerId: string) {
    await applicationsApiService.toggleShowOffer(offerId);
    await dispatch("getOffers");
  },
  async updateWorkflowStatus(
    { commit },
    {
      id,
      payload,
      isList = false
    }: { id: string; payload: ApplicationStatusPayload; isList: boolean }
  ) {
    const workflowSnapshot = await applicationsApiService.updateWorkflowStatus(
      id,
      payload
    );

    if (isList) {
      commit("updateApplicationDataInList", {
        application_id: id,
        path: "workflow_status",
        value: workflowSnapshot.workflow_status
      });
    } else {
      commit("setApplicationStatus", workflowSnapshot.workflow_status);
    }
  },
  async updateApplicationOwnerPhoneStatus(
    { commit, dispatch },
    payload: {
      applicationId: string;
      phone: Omit<DealTelephone, "is_primary"> & { name: string };
      shouldBlock: boolean;
      isBusiness: boolean;
    }
  ) {
    if (payload.shouldBlock) {
      await communicationService.blockPhoneNumber(omit(payload, "shouldBlock"));
    } else {
      await communicationService.unblockPhoneNumber({
        applicationId: payload.applicationId,
        phone: { number: payload.phone.number }
      });
    }
    await dispatch("syncActiveToAll");
    commit("setPhoneStatus", payload);
  }
};

const getters: GetterTree<IApplicationsState, IRootState> = {
  isSearchIndexed(_, __, rootState) {
    const options = rootState.options.dashboardSettings;
    return !!options?.dealsSearchIndexed;
  },
  all(state) {
    return state.all;
  },
  meta(state) {
    return state.meta;
  },
  active(state) {
    return state.active;
  },
  workflowStatusesByDealId: (state) => (id: string) => {
    return state.workflow_statuses?.[id] || [];
  },
  fundingAdvisors(state) {
    return state.fundingAdvisors;
  },
  clientFundingAdvisors(state) {
    return (clientId: number) => state.clientFundingAdvisors[clientId] || [];
  },
  underwriters(state) {
    return state.underwriters;
  },
  clientUnderwriters(state) {
    return (clientId: number) => state.clientUnderwriters[clientId] || [];
  },
  analysts(state) {
    return state.analysts;
  },
  clientAnalysts(state) {
    return (clientId: number) => state.clientAnalysts[clientId] || [];
  },
  clientTrackingTokens(state) {
    return state.clientTrackingTokens;
  },
  additionalTrackingTokens(state) {
    return state.additionalTrackingTokens;
  },
  currentPhase(state) {
    return state.currentPhase;
  },
  placements(state) {
    return state.placements;
  },
  offers(state) {
    return state.offers;
  },
  approvedOffers(state) {
    return (state.offers || []).filter((offer) => offer.status === 1);
  },
  approvedOffersGrouped(state) {
    return state.offers.length
      ? groupBy(
          state.offers.filter((offer) => offer.status === 1),
          "offer_product_type"
        )
      : [];
  },
  fundedOffersGrouped(state) {
    return groupBy(
      (state.offers || []).filter((offer) => {
        const hasFunded = !!offer.funded_date;
        const isErcFunded =
          !!offer?.funding_data?.erc_credit_amount &&
          offer?.product.id === Number(PRODUCT_TYPE_ERC);

        return offer.status === 1 && (hasFunded || isErcFunded);
      }),
      "offer_product_type"
    );
  },
  offersLoading(state) {
    return state.offersLoading;
  },
  ein(state): string | undefined {
    const country = state.active.business?.address?.country?.toLowerCase();
    const piis = ["BN", "EIN"];
    const piiIndex = isGivenCountryCanada(country) ? 0 : 1;
    const pii = state.active.business?.piis?.find(
      (item) => piis[piiIndex] === item.key
    )?.value;
    return (
      pii ||
      state.active.business?.piis?.find(
        (item) => piis[piiIndex === 1 ? 0 : 1] === item.key
      )?.value
    );
  },
  ssn(state) {
    return state.active.personal_information?.piis?.find(
      (item) => item.key === "SSN"
    )?.value;
  },
  count(state) {
    return state.count;
  },
  naics_code(state) {
    return state.active.naics_code;
  },
  industry(state) {
    return state.active.industry;
  },
  business_notes(state) {
    return state.active.business_notes;
  },
  document_verifications(state) {
    return state.active.document_verifications;
  },
  offersDeclinedInfo(state): IOfferDeclinedInfo {
    return {
      date: formatDateCustom(
        state.active.offers_declined_at || "",
        "MMM dd, yyyy"
      ),
      feedback: state.active.offers_declined_feedback || ""
    };
  },
  cfaClientPreferences(state) {
    return state.clientPreferences;
  },
  loading(state) {
    return state.loading;
  },
  singlePlacement(state) {
    return state.singlePlacement;
  },
  placementEmailContent(state, _, __, rootGetters) {
    const businessNotes = [];
    //to handle old business notes
    const { currentNotes } = useNotesStore();
    const activeBusinessNotes = currentNotes.data?.filter(
      (note) =>
        note.notable_type ===
          getEnumKeyFromValue(NotableType, NoteType.Business) &&
        Number(note.notable_id) === state.active.business.id
    );
    if (state.active.business_notes?.length) {
      businessNotes.push({ insert: state.active.business_notes });
    }
    if (!activeBusinessNotes) {
      businessNotes.push({ insert: "-" });
    } else {
      businessNotes.push({ insert: "\n" });
      activeBusinessNotes.forEach((note) => {
        if (note.visibility_scope == 1) {
          businessNotes.push({ insert: `- ${note.comment}` });
          businessNotes.push({ insert: "\n" });
        }
      });
    }

    const getIndustryType: (industry: number) => IIndustry =
      rootGetters["options/industryType"];

    const getBusinessEntityType: OptionCallback =
      rootGetters["options/businessEntityTypes"];

    const getUseOfFunds: OptionCallback = rootGetters["options/useOfFunds"];

    const industryTypeId = state.active.business?.industry_type_id;
    const businessEntityTypeId = state.active.business?.business_entity_type_id;
    const useOfFundsId = state.active.use_of_fund_id;

    const industryType = industryTypeId
      ? getIndustryType(industryTypeId)?.label
      : "-";
    const businessEntityType = businessEntityTypeId
      ? getBusinessEntityType(businessEntityTypeId)
      : "-";
    const useOfFunds = useOfFundsId ? getUseOfFunds(useOfFundsId) : "-";

    const ops = [
      { insert: "Please consider the following business for funding. \n\n" },
      { insert: "Business Legal Name: ", attributes: { bold: true } },
      { insert: state.active.business?.business_legal_name || "-" },
      { insert: "\n" },
      { insert: "Business DBA Name: ", attributes: { bold: true } },
      { insert: state.active.business?.doing_business_as || "-" },
      { insert: "\n" },
      { insert: "Business Start Date: ", attributes: { bold: true } },
      { insert: formatDateCustom(state.active.business?.business_start_date) },
      { insert: "\n" },
      { insert: "Industry: ", attributes: { bold: true } },
      { insert: industryType },
      { insert: "\n" },
      { insert: "NAICS Code: ", attributes: { bold: true } },
      {
        insert: state.active.naics_code ? String(state.active.naics_code) : "-"
      },
      { insert: "\n" },
      { insert: "Owner Name: ", attributes: { bold: true } },
      {
        insert: `${state.active.personal_information?.first_name} ${state.active.personal_information?.last_name}`
      },
      { insert: "\n" },
      { insert: "Date of Birth: ", attributes: { bold: true } },
      {
        insert: formatDateCustom(
          state.active.personal_information?.date_of_birth
        )
      },
      { insert: "\n" },
      { insert: "Entity Type: ", attributes: { bold: true } },
      { insert: businessEntityType },
      { insert: "\n" },
      { insert: "Company Address: ", attributes: { bold: true } },
      { insert: state.active.business?.address?.address_line || "-" },
      { insert: "\n" },
      { insert: "Company City: ", attributes: { bold: true } },
      { insert: state.active.business?.address?.city || "-" },
      { insert: "\n" },
      { insert: "Company State: ", attributes: { bold: true } },
      { insert: state.active.business?.address?.state || "-" },
      { insert: "\n" },
      { insert: "Company ZIP: ", attributes: { bold: true } },
      { insert: state.active.business?.address?.zip || "-" },
      { insert: "\n" },
      { insert: "Company Website: ", attributes: { bold: true } },
      { insert: state.active.business?.website_address || "-" },
      { insert: "\n" },
      { insert: "Personal Address: ", attributes: { bold: true } },
      {
        insert: state.active.personal_information?.address?.address_line || "-"
      },
      { insert: "\n" },
      { insert: "Personal City: ", attributes: { bold: true } },
      { insert: state.active.personal_information?.address?.city || "-" },
      { insert: "\n" },
      { insert: "Personal State: ", attributes: { bold: true } },
      { insert: state.active.personal_information?.address?.state || "-" },
      { insert: "\n" },
      { insert: "Personal ZIP: ", attributes: { bold: true } },
      { insert: state.active.personal_information?.address?.zip || "-" },
      { insert: "\n" },
      { insert: "Amount Requested: ", attributes: { bold: true } },
      { insert: formatMoney(state.active.prequalification?.requested_amount) },
      { insert: "\n" },
      { insert: "Monthly Revenue: ", attributes: { bold: true } },
      {
        insert:
          (state.active.business?.actual_monthly_revenue &&
            formatMoney(state.active.business?.actual_monthly_revenue)) ||
          "-"
      },
      { insert: "\n" },
      { insert: "Use of Funds: ", attributes: { bold: true } },
      { insert: useOfFunds },
      { insert: "\n" },
      { insert: "Business Notes: ", attributes: { bold: true } },
      ...businessNotes,
      { insert: "\n\n" },
      {
        insert: "Please send all approvals, offers, and/or declines to "
      },
      {
        insert: "offers@lendflow.com",
        attributes: { link: "mailto:offers@lendflow.com" }
      },
      { insert: "." },
      { insert: "\n\n" }
    ];

    return new Delta(ops);
  },
  activeApplicationAttributes(state) {
    return state.currentAppAttributes;
  },
  filters(state) {
    return state.filters;
  },
  smartviewBuilderOpen(state) {
    return state.smartviewBuilderOpen;
  },
  smartviewBuilderModel(state) {
    return state.smartviewBuilderModel;
  },
  activeFilterId(state) {
    return state.activeFilterId;
  },
  activeSmartviewCategory(state) {
    return state.activeSmartviewCategory;
  },
  metrics(state) {
    return state.metrics;
  },
  fundingTransactions(state) {
    return state.fundingTransactions;
  },
  systemPreferences(state) {
    return state.systemPreferences;
  },
  previousId(state) {
    return state.previousId;
  },
  nextId(state) {
    return state.nextId;
  },
  bankData(state) {
    return state.bankData;
  },
  plaidData(state) {
    return state.plaidData;
  },
  plaidDataLoading(state) {
    return state.plaidDataLoading;
  },
  moneythumbAnalytics(state) {
    return state.moneythumbAnalytics;
  },
  selectedAffiliatedBusinessId(state) {
    return state.selectedAffiliatedBusinessId;
  },
  selectedAffiliatedIndividualId(state) {
    return state.selectedAffiliatedIndividualId;
  },
  widgetInfo(state) {
    return state.widgetInfo;
  },
  moneythumbCfaAccountNumber(state) {
    return state.moneythumbCfaAccountNumber;
  },
  files(state) {
    return state.files;
  }
};

export const applications = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
